@font-face {
  font-family: 'Gotham-MediumItalic';
  src: url(fonts/Gotham-MediumItalic.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Black';
  src: url(fonts/Gotham-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url(fonts/Gotham-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Book';
  src: url(fonts/Gotham-Book.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham-Medium';
  src: url(fonts/Gotham-Medium.otf) format('opentype');
}

body {
  margin: 0;
  font-family: 'Gotham-Book', 'Roboto', 'Oxygen', Arial,
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.masthead {
  height: 100vh;
  min-height: 500px;
  background-image: url('images/herobg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero {
  color: #fff;
  font-weight: bold !important;
  text-shadow: 2px 2px #000;
}

.masthead h1 {
  font-size: 2rem;
  font-family: 'Gotham-Bold' !important;
}

.masthead .lead {
  text-shadow: 1px 1px #000;
  max-width: 855px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Gotham-Book';
  margin-bottom: 50px;
  font-size: 1rem;
}

.logo {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 40px;
  text-align: center;
  z-index: 1;
}

.search{
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, .1);
    
  }

  .search input{
    height: 60px;
    text-indent: 25px;
    border: 2px solid #d6d4d4;
  }


  .search input:focus{
    box-shadow: none;
    border: 2px solid #11996E;
  }

  .search .fa-search{
    position: absolute;
    top: 20px;
    left: 16px;
  }

  .search button{
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: #11996E;
    border-color: #11996E
  }

  .search button:hover{
    background: #00704d;
  }

  .search-btn {
    width: 80px !important;
  }

  @media screen and (min-width: 768px) {
    .masthead h1 {
      font-size: 3.625rem;
    }

    .masthead .lead {
      font-size: 1.25rem;
    }
  }

  @media screen and (max-height: 700px) {
    .logo {
        display: none !important;
    }
  }