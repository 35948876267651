.collapseable-area {
    margin-top: 20px;
    padding: 20px;
    padding-bottom: 0px;
    background-color: #fff;
}

.collapseable-area p {
    font-family: "Gotham-Medium";
}

.collapseable-area button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;

    width: 100%;
    text-align: left;
}

.collapseable-area span {
    float: right;
    margin-top: -40px;
    color: #878787;
}

.collapseable-area img {
    margin-left: 4px;
    width: 12px;
    fill: #878787;
    margin-top: -3px;
}

.rotated {
    transform: rotate(180deg);
}

.collapseable-area-expanded {
    margin-top: 0;
    margin-bottom: 40px;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
    background-color: #fff;
}