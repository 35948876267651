.field {
    height: 400px;
    position: relative;
    text-align: center;
    color: white;
}

.field-image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: blue;

}


.field-text-container {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    margin-bottom: 0;
    padding-bottom: 0;
    background-color: #fff;

    padding-bottom: 0 !important;
    margin-bottom: 0 !important;

    border-radius: 8px;
}

.field-text-container p {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;

}