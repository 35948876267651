.single-field-detail {
    background-color: #F7F9FC;
    padding: 30px;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.single-field-detail h2 {
    color: #404040;
    font-family: "Gotham-Medium";
    font-size: 1.25rem;
    font-weight: 900 !important;
    margin-bottom: 0;
}

.detail-area {
    margin-top: 30px;
    margin-bottom: 30px;
}

.rating {
    margin-top: 5px;
}

.rating-group {
    display: flex;
}

.rating-group p {
    margin-left: 15px;
    margin-top: 5px;
}