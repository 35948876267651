.reviews {

}

.review-groups {
    display: flex;
    overflow-x: auto;
}

.review-group {
    min-width: 150px;
    height: 175px;
    margin-right: 20px;
    margin-bottom: 15px;
}

.review-group-title {
    font-family: "Gotham-Medium";
    margin-bottom: 0;
}

.review-group-overall-rating {
    margin-top: 5px;
}

.review-breakdown {
    
}

.review-breakdown-row {
    display: flex;
}

.review-breakdown-row p {
    font-size: 14px;
    margin-bottom: 0;
}

.progress {
    width: 50px;
    margin-top: 8px;
    margin-left: 4px;
    margin-right: 7px;

    background-color: #f0f0f0 !important;
}

.progress > * { background-color:#ec9e32 !important; }

.star-count {
    width: 50px;
}

.reviewer {
    font-family: "Gotham-Medium";
    margin-bottom: 5px;
}

.review-content {
    margin-top: 20px;
    margin-bottom: 20px;
}

.individual-review {
    margin-top: 30px;
    margin-bottom: 30px;
}

.individual-review-group {
    display: flex;
}

.individual-review-group p {
    margin-top: 0;
    margin-bottom: 0;
    width: 190px;
}

hr {
    color: #F4F4F4;
    background-color: #F4F4F4;
    border: 0; border-top: 1px solid #D8D8D8 !important;
    margin-bottom: 70px;
}
