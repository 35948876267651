li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.indented {
    margin-left: 40px;
}

.double-indented {
    margin-left: 60px;
}
