.loading {
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    margin-top: 50px;
}

.field-detail {
    color: #404040 !important;
    text-align: left !important;
    margin-bottom: 40px;
}

.field-detail h2 {
    font-family: "Gotham-Bold";
    font-weight: bold !important;
    margin-bottom: 0;
}