.search-nav-bar {
    margin-top: 15px;
    background-color: #fff !important;
}

.search-logo {
    padding-top: 10px;
}

.search-count {
    padding-top: 40px;
}

.fields {

}

.field {
    margin-bottom: 20px;
}

.search-result-search{
    position: relative;
    box-shadow: 0 0 40px rgba(51, 51, 51, .1);
      
    }
  
    .search-result-search input{
      height: 60px;
      text-indent: 25px;
      border: 2px solid #d6d4d4;
    }
  
  
    .search-result-search input:focus{
      box-shadow: none;
      border: 2px solid #11996E;
    }
  
    .search-result-search .fa-search{
      position: absolute;
      top: 20px;
      left: 16px;
    }

.search-result-search button{
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: #0D6E4F;
    border-color: #0D6E4F
  }

  .search-result-search button:hover{
    background: #11996E;
  }

@media screen and (min-width: 768px) {
    .search-result-search {
        min-width: 500px;
    }
}

.troubleshooting {
  margin-top: 55px;
  font-weight: bold;
}

.troubleshooting li {
  font-weight: bold;
}